import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import NavButton from '../NavButton';

const Header = () => (
  <header
    style={{
      zIndex: 100,
    }}
    className="sticky top-0 bg-white shadow"
  >
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-56 mr-3">
          <LogoIcon />
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink>
        <a className="px-4" target="_blank" href="https://dev.bankingapi.io/">
          Developers
        </a>
      </div>
      <div className="hidden md:block">
        <NavButton className="text-sm">Register</NavButton>
      </div>
    </div>
  </header>
);

export default Header;
