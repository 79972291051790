import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieConsentComponent from '../CookieConsent';
import { ToastContainer } from 'react-toastify';
import Footer from './Footer';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';
import './layout.css';

const Layout = ({ children }) => {
  return (
    <ParallaxProvider>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
      <CookieConsentComponent />
      <ToastContainer />
    </ParallaxProvider>
  );
};

export default Layout;
