import React from 'react';
import { navigate } from 'gatsby';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
};

const NavButton = ({ children, className = '', size, type = 'button' }) => {
  return (
    <button
      onClick={() => {
        if (type === 'button') {
          return navigate('#register');
        } else {
          return false;
        }
      }}
      type={type}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        bg-primary
        hover:bg-primary-darker
        rounded
        text-white
    `}
    >
      {children}
    </button>
  );
};

export default NavButton;
