import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a target="_new" href="https://accessholding.com/#about">
              Access Microfinance Holding AG
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a target="_new" href="https://accessholding.com/legal-notice-data-protection/">
              Legal Notice and Data Protection
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a target="_new" href="https://www.youtube.com/channel/UCZBSo5xaUbvpt74Ki-LU7iQ">
              Youtube
            </a>
          </li>
          <li>
            <a target="_new" href="https://linkedin.com/company/access-microfinance-holding-ag">
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
