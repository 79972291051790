import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept All Cookies"
        cookieName="myBankingAPI"
        enableDeclineButton
        declineButtonText="Decline (optional)"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b' }}
        declineButtonClasses="declineButtonClass"
        expires={150}
      >
        By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to
        enhance site navigation, analyze site usage, and assist in our marketing efforts.
      </CookieConsent>
    </>
  );
};

export default CookieConsentComponent;
